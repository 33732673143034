import {createStylesParams, StyleParamType, wixColorParam, wixFontParam} from '@wix/tpa-settings';

type IStylesParams = {
  cart_textFontStyle: StyleParamType.Font;
  cartHeaderBackgroundColor: StyleParamType.Color;
  cartHeaderDividerColor: StyleParamType.Color;
  cartHeaderDividerWidth: StyleParamType.Number;
  cartHeaderDividerPadding: StyleParamType.Number;
  cartTitleFont: StyleParamType.Font;
  cartTitleColor: StyleParamType.Color;
  cartSubtitleFont: StyleParamType.Font;
  cartSubtitleColor: StyleParamType.Color;
  cartCloseButtonColor: StyleParamType.Color;
  cartItemPriceFont: StyleParamType.Font;
  cartItemPriceColor: StyleParamType.Color;
  cartItemStrikethroughPriceColor: StyleParamType.Color;
  cartItemTotalPriceFont: StyleParamType.Font;
  cartItemTotalPriceColor: StyleParamType.Color;
  cartItemQuantityBackgroundColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  cart_textFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartHeaderBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  cartHeaderDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartHeaderDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(1),
  },
  cartHeaderDividerPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(20),
  },
  cartTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 20,
    }),
  },
  cartTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartSubtitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartSubtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartCloseButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemPriceFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  cartItemPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemStrikethroughPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4', 1),
  },
  cartItemTotalPriceFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartItemTotalPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  cartItemQuantityBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 1),
  },
});
